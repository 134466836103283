import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';

const VerticalNavbar = () => {
  const [hoverProfile, setHoverProfile] = useState(false);
  const [hoverExperience, setHoverExperience] = useState(false);
  const [hoverProjects, setHoverProjects] = useState(false);
  const [hoverSkills, setHoverSkills] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateScreen = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  } 

  useEffect(() => {
    window.addEventListener("resize", updateScreen);
    return () => window.removeEventListener("resize", updateScreen);
  }, []);

  return (
    <Fade in={true}
      style={{ transitionDelay: '500ms' }}
    >
      {
        width > 1500 ?
      <nav className='verticalNavbar'>
        <div className='tabs'>
          <Link onMouseEnter={() => setHoverProfile(true)} onMouseLeave={() => setHoverProfile(false)} className='tabsLink' to="/">
            <span className='tabsIcon'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverProfile ? '../assets/userBlack.png' : '../assets/user.png')} />
            </span>
            <span className='tabsText'>
              Profile
            </span>
          </Link>
          <Link onMouseEnter={() => setHoverExperience(true)} onMouseLeave={() => setHoverExperience(false)} className='tabsLink' to="/experience">
            <span className='tabsIcon'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverExperience ? '../assets/experienceBlack.png' : '../assets/experience.png')} />
            </span>
            <span className='tabsText'>
              Experience
            </span>
          </Link>
          <Link onMouseEnter={() => setHoverProjects(true)} onMouseLeave={() => setHoverProjects(false)} className='tabsLink' to="/projects">
            <span className='tabsIcon'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverProjects ? '../assets/project-managementBlack.png' : '../assets/project-management.png')} />
            </span>
            <span className='tabsText'>
              Projects
            </span>
          </Link>
          <Link onMouseEnter={() => setHoverSkills(true)} onMouseLeave={() => setHoverSkills(false)} className='tabsLink' to="/skills">
            <span className='tabsIcon'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverSkills ? '../assets/pencilBlack.png' : '../assets/pencil.png')} />
            </span>
            <span className='tabsText'>
              Skills
            </span>
          </Link>
        </div>
      </nav>
      :
      <nav className='verticalNavbarMobile'>
        <div className='tabsMobile'>
          <Link onMouseEnter={() => setHoverProfile(true)} onMouseLeave={() => setHoverProfile(false)} className='tabsLinkMobile' to="/">
            <span className='tabsIconMobile'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverProfile ? '../assets/user.png' : '../assets/userBlack.png')} />
            </span>
          </Link>
          <Link onMouseEnter={() => setHoverExperience(true)} onMouseLeave={() => setHoverExperience(false)} className='tabsLinkMobile' to="/experience">
            <span className='tabsIconMobile'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverExperience ? '../assets/experience.png' : '../assets/experienceBlack.png')} />
            </span>
          </Link>
          <Link onMouseEnter={() => setHoverProjects(true)} onMouseLeave={() => setHoverProjects(false)} className='tabsLinkMobile' to="/projects">
            <span className='tabsIconMobile'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverProjects ? '../assets/project-management.png' : '../assets/project-managementBlack.png')} />
            </span>
          </Link>
          <Link onMouseEnter={() => setHoverSkills(true)} onMouseLeave={() => setHoverSkills(false)} className='tabsLinkMobile' to="/skills">
            <span className='tabsIconMobile'>
              <img style={{ height: '40px', width: '40px' }} src={require(hoverSkills ? '../assets/pencil.png' : '../assets/pencilBlack.png')} />
            </span>
          </Link>
        </div>
      </nav>
      }
    </Fade>
  );
};

export default VerticalNavbar;
