import Fade from '@material-ui/core/Fade';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import { useEffect, useState } from 'react';

const Profile = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
  
    const updateScreen = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    } 
  
    useEffect(() => {
      window.addEventListener("resize", updateScreen);
      return () => window.removeEventListener("resize", updateScreen);
    }, []);

    useEffect(() => {
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }, [])

    return (
        <div className={width > 1500 ? "profileMain" : "profileMainMobile"}>
            <div className="everythingAboutMe">
                <div className={width > 1500 ? "personalInfo" : "personalInfoMobile"}>
                        <Fade in={true}
                            style={{ transitionDelay: '500ms' }}
                        >
                    <div className='fullName'>
                            <span className={width > 1500 ? 'name' : "nameMobile"}>Tarik</span>
                            <span className={width > 1500 ? 'surname' : "surnameMobile"}>Valjevac</span>
                    </div>
                        </Fade>
                        <Fade in={true}
                            style={{ transitionDelay: '1500ms' }}
                        >
                    <div className='workTitle'>
                            <span className={width > 500 ? 'position' : 'positionMobile'}>Software Developer</span>
                            <span className={width > 500 ? 'education' : 'educationMobile'}>Bachelor of Software Engineering</span>
                    </div>
                        </Fade>
                </div>
                <Fade in={true}
                    style={{ transitionDelay: '2500ms' }}
                >
                    <div className='aboutMe'>
                        <h3 style={{ fontSize: '26px' }}>About Me:</h3>
                        <p className={width > 700 ? "aboutMeText" : "aboutMeTextMobile"}>
                            My name is Tarik Valjevac and I am a software developer with one year of experience in webpage development regarding E-Commerce.
                            I consider myself a resourceful and motivated learner, apart from being appreciably devoted to my field of study. Furthermore,
                            I am constantly looking for opportunities to further improve and develop my theoretical knowledge and practical skills when it comes to IT sciences.
                        </p>
                        <div className='contactInfo'>
                            <div className='contactSection'>
                                <img style={width > 700 ? {width: '30px', height: '30px', padding: '10px'} : {width: '20px', height: '20px', padding: '7px'}} src={require("../../assets/email.png")}/>
                                <span style={width > 700 ? {fontSize: '20px'} : {fontSize: '14px'}}>tarikvaljak@gmail.com</span>
                            </div>
                            <div className='contactSection'>
                                <img style={width > 700 ? {width: '30px', height: '30px', padding: '10px'} : {width: '20px', height: '20px', padding: '7px'}}  src={require("../../assets/linkedin.png")}/>
                                <span style={width > 700 ? {fontSize: '20px'} : {fontSize: '14px'}}>linkedin.com/in/tarikvaljevac/</span>
                            </div>
                            <div className='contactSection'>
                                <img style={width > 700 ? {width: '30px', height: '30px', padding: '10px'} : {width: '20px', height: '20px', padding: '7px'}}  src={require("../../assets/github.png")}/>
                                <span style={width > 700 ? {fontSize: '20px'} : {fontSize: '14px'}}>github.com/Raiyinn</span>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <Fade in={true}
                style={{ transitionDelay: '3500ms' }}
            >
                <div className='portrait'>
                    <img className={width > 500 ? 'portraitPicture' : 'portraitPictureMobile'} src={require('../../assets/portrait.png')} />
                </div>
            </Fade>
        </div>
    )
}

export default Profile;