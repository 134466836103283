import Fade from '@material-ui/core/Fade';
import { useEffect, useState } from 'react';

const Projects = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
  
    const updateScreen = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    } 
  
    useEffect(() => {
      window.addEventListener("resize", updateScreen);
      return () => window.removeEventListener("resize", updateScreen);
    }, []);

    useEffect(() => {
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }, [])

    return(
        <div className="projectsMain">
            <div className="projectsContainer">
                <Fade in={true}
                    style={{ transitionDelay: '500ms' }}
                >
                <div className="myProject">
                    <div className="projectHeader">
                        Virtual Office
                    </div>
                    <div className={width > 1500 ? "projectSection" : "projectSectionMobile"}>
                        <div className="projectLogo">
                            <img style={{width: '250px', height: '225px'}} src={require("../../assets/vofLogo.png")}/>
                        </div>
                        <div className={width > 1500 ? "projectBody" : "projectBodyMobile"}>
                            <span className="bulletPoint">
                                    {("\u2022")}
                            </span>
                            <span className="projectDesc">
                                The web application started as an idea on a hackathon event I attended with my team, 
                                which was further developed throughout different college subjects.
                            </span>
                        </div>
                    </div>
                </div>
                </Fade>
                <Fade in={true}
                    style={{ transitionDelay: '1500ms' }}
                >
                <div className="myProject">
                    <div className="projectHeader">
                        Tabbled
                    </div>
                    <div className={width > 1500 ? "projectSection" : "projectSectionMobile"}>
                        <div className="projectLogo">
                            <img style={{width: '250px', height: '225px'}} src={require("../../assets/TabbledLogo.png")}/>
                        </div>
                        <div className={width > 1500 ? "projectBody" : "projectBodyMobile"}>
                            <span className="bulletPoint">
                                    {("\u2022")}
                            </span>
                            <span className="projectDesc">
                                The mobile application was an idea for a college project I had with my colleague. 
                                It was developed as a prototype in the Xamarin technology. Earlier this year, I made
                                a new UI/UX design which we plan on developing as a Native Mobile App.  
                            </span>
                        </div>
                    </div>
                </div>
                </Fade>
            </div>
        </div>
    )
}

export default Projects;