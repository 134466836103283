import Fade from '@material-ui/core/Fade';
import { useEffect, useState } from 'react';

const Skills = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateScreen = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreen);
        return () => window.removeEventListener("resize", updateScreen);
    }, []);

    useEffect(() => {
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }, [])

    return (
        <div className={width > 1000 ? "skillsMain" : "skillsMainMobile"}>
            <div className="skillsContainerMain">
                <div className="softSkills">
                    <Fade in={true}
                        style={{ transitionDelay: '500ms' }}
                    >
                        <div className="skillsTitleSection">
                            <span className="skillsTitle">
                                Soft Skills:
                            </span>
                        </div>
                    </Fade>
                    <Fade in={true}
                        style={{ transitionDelay: '1500ms' }}
                    >
                        <div className={width > 1000 ? "skillsContainer" : "skillsContainerMobile"}>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/communication.png")} />
                                </div>
                                <div className="skillName">
                                    Communication
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/teamwork.png")} />
                                </div>
                                <div className="skillName">
                                    Teamwork
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/problemsolving.png")} />
                                </div>
                                <div className="skillName">
                                    Problem-Solving
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/adaptability.png")} />
                                </div>
                                <div className="skillName">
                                    Adaptability
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/creativity.png")} />
                                </div>
                                <div className="skillName">
                                    Creativity
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/innovation.png")} />
                                </div>
                                <div className="skillName">
                                    Innovation
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="technologySkills">
                    <Fade in={true}
                        style={{ transitionDelay: '2500ms' }}
                    >
                        <div className="skillsTitleSection">
                            <span className="skillsTitle">
                                Technologies
                            </span>
                        </div>
                    </Fade>
                    <Fade in={true}
                        style={{ transitionDelay: '3500ms' }}
                    >
                        <div className={width > 1000 ? "skillsContainer" : "skillsContainerMobile"}>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/html5.png")} />
                                </div>
                                <div className="skillName">
                                    HTML
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/css3.png")} />
                                </div>
                                <div className="skillName">
                                    CSS
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/js.png")} />
                                </div>
                                <div className="skillName">
                                    JavaScript
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/reactjs.png")} />
                                </div>
                                <div className="skillName">
                                    React.js/Native
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/cpp.png")} />
                                </div>
                                <div className="skillName">
                                    C++
                                </div>
                            </div>
                            <div className="skillsSection">
                                <div className="skillLogo">
                                    <img className={width > 1000 ? "skillLogoImg" : "skillLogoImgMobile"} src={require("../../assets/python.png")} />
                                </div>
                                <div className="skillName">
                                    Python
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default Skills;