import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './styles/App.css';
import Profile from './components/views/Profile';
import Experience from './components/views/Experience';
import Skills from './components/views/Skills';
import Projects from './components/views/Projects';
import VerticalNavbar from "./components/VerticalNavbar";
import { useEffect, useState } from 'react';

function App(props, state) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateScreen = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  } 

  useEffect(() => {
    window.addEventListener("resize", updateScreen);
    return () => window.removeEventListener("resize", updateScreen);
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [])

  return (
    <div style={width > 1500 ? {paddingLeft: '450px', flexDirection: 'row', height: '100vh'} : {padding: '100px 20px', flexDirection: 'column', height: '100%'}} className="main">
      <div className="mainContainer">
        <Routes>
          <Route path="/" element={<Profile />}/>
          <Route path="/experience" element={<Experience />}/>
          <Route path="/projects" element={<Projects />}/>
          <Route path="/skills" element={<Skills />}/>
        </Routes>
      </div>
      <VerticalNavbar />
    </div>
  );
}

export default App;