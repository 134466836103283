import Fade from '@material-ui/core/Fade';
import { useEffect, useState } from 'react';

const Experience = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
  
    const updateScreen = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    } 
    
    useEffect(() => {
      window.addEventListener("resize", updateScreen);
      return () => window.removeEventListener("resize", updateScreen);
    }, []);

    useEffect(() => {
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }, [])

    return (
        <div className={width > 1500 ? "experienceMain" : "experienceMainMobile"}>
            <div className="experienceContainer">
                <Fade in={true}
                    style={{ transitionDelay: '500ms' }}
                >
                    <div className="myExperience">
                        <div className="experienceHeader">
                            <span className="experienceTitle">
                                Mathematics Instructor
                            </span>
                            <span className="experienceFirm">
                                Self-Employed
                            </span>
                        </div>
                        <div className={width > 1500 ? "experienceBody" : "experienceBodyMobile"}>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    During my senior high school year and my first college year, I lectured numerous students about basic mathematics and
                                    engineering and discrete mathematics.
                                </span>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade in={true}
                    style={{ transitionDelay: '1500ms' }}
                >
                    <div className="myExperience">
                        <div className="experienceHeader">
                            <span className="experienceTitle">
                                Junior Frontend Developer
                            </span>
                            <span className="experienceFirm">
                                Commerce Care d.o.o - AI
                            </span>
                        </div>
                        <div className={width > 1500 ? "experienceBody" : "experienceBodyMobile"}>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    Worked on the development and maintenance of various E-Commerce web pages.
                                </span>
                            </div>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    Worked on fixing occurring bugs on formerly mentioned web pages.
                                </span>
                            </div>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    Attending meeting with clients, updating them on work done and discussing further progression.
                                </span>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade in={true}
                    style={{ transitionDelay: '2500ms' }}
                >
                    <div className="myExperience">
                        <div className="experienceHeader">
                            <span className="experienceTitle">
                                Mobile App Developer
                            </span>
                            <span className="experienceFirm">
                                Rokpay d.o.o
                            </span>
                        </div>
                        <div className={width > 1500 ? "experienceBody" : "experienceBodyMobile"}>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    Worked on the development of the Mobile Application.
                                </span>
                            </div>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    Attended team meetings which included problem-solving, brainstorming etc.
                                </span>
                            </div>
                            <div className="experienceSection">
                                <span className="bulletPoint">
                                    {("\u2022")}
                                </span>
                                <span className="experienceDesc">
                                    Attended many QA sessions before the web page was scheduled to go live.
                                </span>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Experience;